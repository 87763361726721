<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="920"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card v-if="loading" min-width="320">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="grey"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row v-if="notEditable">
              <v-col cols="12">
                <v-alert dense type="warning">
                  予約ステータスではないため編集はできません。
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-show="!isCreate">
              <v-col cols="12">
                <v-text-field
                  label="グループ名"
                  :value="displayGroupName(item.group_id)"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-show="!isCreate">
              <v-col cols="12">
                <v-text-field
                  v-model="item.message_id"
                  label="メッセージID"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h4
                  :class="
                    datetimeErrorMessage != ''
                      ? ['red--text', 'text--accent--2']
                      : 'text--secondary'
                  "
                >
                  配信日時
                </h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  v-model="item.execute_now"
                  :disabled="!isCreate"
                  column
                >
                  <v-radio :value="true">
                    <template v-slot:label>
                      <p class="mb-0 mr-4">
                        即時配信
                      </p>
                      <p class="mb-0 caption text--secondary">
                        保存すると即時配信が開始されます。
                      </p>
                    </template>
                  </v-radio>
                  <v-radio :value="false">
                    <template v-slot:label>
                      <p class="mb-0 mr-4">
                        予約配信
                      </p>
                      <p class="mb-0 caption text--secondary">
                        以下で指定した日時にLINEメッセージが配信されます。<br />
                        ※5分単位で設定することができます。
                      </p>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-show="datetimeErrorMessage != ''">
              <v-col cols="12">
                <p
                  class="caption red--text text--accent--2"
                  style="white-space: pre-line"
                >
                  {{ datetimeErrorMessage }}
                </p>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-date-picker
                v-model="item.date"
                :reactive="true"
                :disabled="item.execute_now"
                @change="datetimeValidate"
              ></v-date-picker>
              <v-time-picker
                v-model="item.time"
                :allowed-minutes="allowedStep"
                format="24hr"
                scrollable
                :disabled="item.execute_now"
                @change="datetimeValidate"
              ></v-time-picker>
            </v-row>
            <v-row>
              <v-col>
                <p class="caption text--secondary">
                  以下に入力したメッセージが送信されます
                </p>
                <v-select
                  v-model="fixedPhrase"
                  label="定型文"
                  :items="fixedPhraseList"
                  item-text="label"
                  item-value="value"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      :color="mainColor"
                      depressed
                      dark
                      small
                      @click="insertFixedPhrase"
                      >送信メッセージに設定</v-btn
                    >
                  </template>
                </v-select>
                <v-textarea
                  v-model="item.message"
                  label="送信メッセージ"
                  counter="2048"
                  :rules="rules.required"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="item.target_type"
                  label="ターゲットタイプ"
                  :items="messageDeliveryTargetTypeList"
                  item-text="label"
                  item-value="value"
                  :rules="rules.required"
                  @change="resetTarget"
                ></v-select>
                <p class="caption text--secondary">
                  ターゲットタイプ：ユーザー＝任意のユーザーにメッセージを送信します。<br />
                  ターゲットタイプ：グループ＝グループに所属するユーザー全員にメッセージを送信します。<br />
                  ターゲットタイプ：センサ＝選択したセンサの通知をONにしているユーザーにメッセージを送信します。
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  ref="targetSelect"
                  v-model="item.target"
                  :items="targetItem"
                  label="ターゲット"
                  item-text="name"
                  item-value="id"
                  :rules="rules.required"
                  :disabled="item.target_type === ''"
                  :menu-props="{ top: true, maxHeight: 500 }"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-text-field
                        v-model="targetSearch"
                        label="検索"
                        append-icon="mdi-magnify"
                      ></v-text-field>
                    </v-list-item>
                    <v-list-item v-if="showTargetSerchByGroup">
                      <v-select
                        v-model="targetSerachByGroup"
                        label="グループで絞り込み"
                        :items="groupNames"
                        item-text="name"
                        item-value="id"
                        clearable
                      ></v-select>
                    </v-list-item>
                    <v-list-item>
                      <v-radio-group v-model="targetSort" label="並び替え" row>
                        <v-radio label="昇順" value="asc"></v-radio>
                        <v-radio label="降順" value="desc"></v-radio>
                      </v-radio-group>
                    </v-list-item>
                    <v-list-item ripple>
                      <v-list-item-action @click="toggleTargetSelect">
                        <v-icon
                          :color="
                            item.target.length > 0 ? 'indigo darken-4' : ''
                          "
                        >
                          {{ targetSelectIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content @click="toggleTargetSelect">
                        <v-list-item-title>
                          全て選択
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:append-item>
                    <v-row>
                      <v-col class="d-flex flex-row-reverse mr-5">
                        <v-btn depressed @click="closeTargetSelect">OK</v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-select>
                <p class="caption text--secondary">
                  ターゲットタイプ「ユーザー」「センサ」を選択した場合は複数の対象を選択できます。<br />
                  選択が終わればリスト最下部の「OK」ボタンを押してください。
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="close">
            キャンセル
          </v-btn>
          <v-btn
            :color="mainColor"
            :disabled="notEditable"
            depressed
            :dark="!notEditable"
            @click="save"
            >保存</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { postMessageDelivery, putMessageDelivery } from '@/api'
import {
  MAIN_COLOR,
  MESSAGE_DELIVERY_STATUS_RESERVED,
  MESSAGE_DELIVERY_TARGET_TYPE_USER,
  MESSAGE_DELIVERY_TARGET_TYPE_MEMBER_USER,
  MESSAGE_DELIVERY_TARGET_TYPE_GROUP,
  MESSAGE_DELIVERY_TARGET_TYPE_DEVICE,
  MESSAGE_DELIVERY_TARGET_TYPE_LIST,
  FIXED_PHRASE_LIST
} from '@/constants'

export default {
  name: 'MessageEditor',
  props: {
    dialog: Boolean,
    editGroupId: String,
    editMessageId: String
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      valid: true,
      rules: {
        required: [v => !!v || '必須入力です']
      },
      datetimeErrorMessage: '',
      defaultItem: {
        group_id: '',
        message_id: '',
        date: '',
        time: '',
        timestamp: 0,
        status: '',
        target_type: '',
        target: [],
        message: '',
        execute_now: false
      },
      editItem: {
        group_id: '',
        message_id: '',
        date: '',
        time: '',
        timestamp: 0,
        status: '',
        target_type: '',
        target: [],
        message: '',
        execute_now: false
      },
      targetSearch: '',
      targetSerachByGroup: '',
      targetSort: 'asc',
      fixedPhrase: '',
      fixedPhraseList: FIXED_PHRASE_LIST,
      allowedStep: m => m % 5 === 0
    }
  },
  computed: {
    ...mapGetters([
      'userNames',
      'userNamesByMember',
      'groupNames',
      'deviceNames',
      'messageDeliveryById',
      'isAdminGroup'
    ]),
    isCreate() {
      return this.editGroupId === '' || this.editMessageId === ''
    },
    title() {
      return this.isCreate ? '新規登録' : '編集'
    },
    item() {
      return this.editItem
    },
    targetItem() {
      let items = []
      switch (this.item.target_type) {
        case MESSAGE_DELIVERY_TARGET_TYPE_USER:
          items = this.userNames.filter((item, index) => index !== 0)
          break
        case MESSAGE_DELIVERY_TARGET_TYPE_MEMBER_USER:
          items = this.userNamesByMember.filter((item, index) => index !== 0)
          break
        case MESSAGE_DELIVERY_TARGET_TYPE_GROUP:
          items = this.groupNames.filter((item, index) => index !== 0)
          break
        case MESSAGE_DELIVERY_TARGET_TYPE_DEVICE:
          items = this.deviceNames.filter((item, index) => index !== 0)
          break
      }
      if (this.targetSearch) {
        items = items.filter(item => {
          return item.name.includes(this.targetSearch)
        })
      }
      if (this.isAdminGroup && this.targetSerachByGroup) {
        items = items.filter(item => {
          return item.group === this.targetSerachByGroup
        })
      }
      return this.targetSort === 'asc'
        ? items.sort(this.nameSortAsc)
        : items.sort(this.nameSortDesc)
    },
    isAllSelected() {
      return this.item.target.length === this.targetItem.length
    },
    notEditable() {
      return (
        !this.isCreate &&
        this.item.status &&
        this.item.status !== MESSAGE_DELIVERY_STATUS_RESERVED
      )
    },
    targetSelectIcon() {
      if (this.isAllSelected) {
        return 'mdi-close-box'
      }
      if (this.item.target.length > 0 && !this.isAllSelected) {
        return 'mdi-minus-box'
      }
      return 'mdi-checkbox-blank-outline'
    },
    showTargetSerchByGroup() {
      return (
        this.isAdminGroup &&
        this.item.target_type !== MESSAGE_DELIVERY_TARGET_TYPE_GROUP
      )
    },
    messageDeliveryTargetTypeList() {
      if (this.isAdminGroup) {
        return MESSAGE_DELIVERY_TARGET_TYPE_LIST
      }
      return MESSAGE_DELIVERY_TARGET_TYPE_LIST.filter(
        t => t.value !== MESSAGE_DELIVERY_TARGET_TYPE_MEMBER_USER
      )
    }
  },
  watch: {
    async dialog(openend) {
      if (openend && !this.isCreate) {
        await this.setItems()
      }
    }
  },
  methods: {
    ...mapActions([
      'addMessageDelivery',
      'updateMessageDelivery',
      'setFullscreenLoading'
    ]),
    close() {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetValidation()
      }
      this.editItem = Object.assign({}, this.defaultItem)
      this.editItem.target = []
      this.$emit('close')
    },
    async save() {
      if (!this.datetimeValidate()) {
        return
      }
      if (!this.$refs.form.validate()) {
        return
      }
      this.setFullscreenLoading(true)
      const registerItem = {
        group_id: this.editGroupId,
        message_id: this.editMessageId,
        timestamp: `${this.item.date} ${this.item.time}:00`,
        status: this.item.status,
        target_type: this.item.target_type,
        target: this.item.target,
        message: this.item.message,
        execute_now: this.item.execute_now
      }
      try {
        if (this.isCreate) {
          const res = await postMessageDelivery(registerItem)
          this.addMessageDelivery(res.message_delivery)
        } else {
          const res = await putMessageDelivery(registerItem)
          this.updateMessageDelivery(res.message_delivery)
        }
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.close()
      this.setFullscreenLoading(false)
    },
    datetimeValidate() {
      if (this.item.execute_now) {
        return true
      }
      this.datetimeErrorMessage = ''
      if (this.item.date === '') {
        this.datetimeErrorMessage += '配信日を入力してください。\n'
      }
      if (this.item.time === '') {
        this.datetimeErrorMessage += '配信時間を入力してください。\n'
      }
      if (this.datetimeErrorMessage !== '') {
        return false
      }
      return true
    },
    async setItems() {
      const message = this.messageDeliveryById({
        groupId: this.editGroupId,
        messageId: this.editMessageId
      })
      if (message === undefined) {
        this.close()
        return
      }
      Object.assign(this.editItem, message)
      const timestamp = moment.unix(message.timestamp)
      this.editItem.date = timestamp.format('YYYY-MM-DD')
      this.editItem.time = timestamp.format('HH:mm')
    },
    displayGroupName(id) {
      const group = this.groupNames.find(g => g.id == id)
      if (!group) {
        return ''
      }
      return group.name
    },
    toggleTargetSelect() {
      if (this.isAllSelected) {
        this.resetTarget()
      } else {
        this.item.target = []
        this.targetItem.forEach(item => this.item.target.push(item.id))
      }
    },
    closeTargetSelect() {
      this.$refs.targetSelect.hasColor = false
      this.$refs.targetSelect.isFocused = false
      this.$refs.targetSelect.isMenuActive = false
      this.resetTargetSearch()
    },
    resetTarget() {
      this.item.target = []
      this.resetTargetSearch()
    },
    resetTargetSearch() {
      this.targetSerach = ''
      this.targetSerachByGroup = ''
      this.targetSort = 'asc'
    },
    insertFixedPhrase() {
      if (this.fixedPhrase === '') {
        return
      }
      this.item.message = this.fixedPhrase
    },
    nameSortAsc(a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    },
    nameSortDesc(a, b) {
      if (a.name > b.name) {
        return -1
      }
      if (a.name < b.name) {
        return 1
      }
      return 0
    }
  }
}
</script>

<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col cols="12" sm="9">
          <h1>メッセージ</h1>
          <p class="caption">
            この画面では任意のユーザーやセンサ等を対象にLINEメッセージを送信することができます
          </p>
          <dl>
            <dt>【メッセージ送信方法】</dt>
            <dd>
              <ol class="caption">
                <li>右上の「新規登録」を押す</li>
                <li>即時配信／予約配信を選択する</li>
                <li>
                  予約配信の場合は日時の設定をする（即時配信の場合は日時の設定必要なし）
                </li>
                <li>送信したいメッセージを入力する</li>
                <li>ターゲットタイプ、ターゲットを選択し「保存」を押す</li>
                <li>
                  即時配信の場合は「保存」が押された時点でメッセージ送信されます<br />
                  予約配信の場合は配信日時が到来するとメッセージ送信されます
                </li>
              </ol>
            </dd>
          </dl>
        </v-col>
        <v-col cols="12" sm="3" class="text-right">
          <v-btn :color="mainColor" dark @click.stop="doCreate">
            新規登録
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn icon @click="doLoad(false)">
            <v-progress-circular
              v-show="loading"
              :indeterminate="loading"
            ></v-progress-circular>
            <v-icon v-show="!loading">mdi-reload</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="messageDeliveries"
          :loading="loading"
          :search="search"
        >
          <template v-slot:[`item.group_id`]="{ item }">
            {{ displayGroupName(item.group_id) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ displayStatusName(item.status) }}
          </template>
          <template v-slot:[`item.timestamp`]="{ item }">
            {{ item.timestamp | moment }}
          </template>
          <template v-slot:[`item.target_type`]="{ item }">
            {{ displayTargetTypeName(item.target_type) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click.stop="doEdit(item.group_id, item.message_id)"
              >mdi-pencil</v-icon
            >
            <v-icon small @click="doDelete(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            現在登録されたメッセージはありません。
          </template>
        </v-data-table>
      </v-card>
      <message-editor
        :dialog="dialog"
        :editGroupId="editGroupId"
        :editMessageId="editMessageId"
        @close="close"
      ></message-editor>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import {
  getMessageDeliveries,
  deleteMessageDelivery,
  getUsers,
  getGroups,
  getDevices
} from '@/api'
import MessageEditor from '@/components/message-editor'
import {
  MAIN_COLOR,
  MESSAGE_DELIVERY_STATUS_LIST,
  MESSAGE_DELIVERY_TARGET_TYPE_LIST
} from '@/constants'

export default {
  name: 'MessageIndex',
  components: {
    MessageEditor
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      messageDeliveryStatusList: MESSAGE_DELIVERY_STATUS_LIST,
      messageDeliveryTargetTypeList: MESSAGE_DELIVERY_TARGET_TYPE_LIST,
      loading: false,
      search: '',
      dialog: false,
      editGroupId: '',
      editMessageId: '',
      headers: [
        { text: '登録グループ名', value: 'group_id', sortable: true },
        { text: 'メッセージID', value: 'message_id', sortable: true },
        { text: 'ステータス', value: 'status', sortable: true },
        { text: '配信日時', value: 'timestamp', sortable: true },
        { text: 'ターゲットタイプ', value: 'target_type', sortable: true },
        {
          text: '編集 / 削除',
          value: 'action',
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['messageDeliveries', 'groupNames'])
  },
  created() {
    this.doLoad(true)
  },
  methods: {
    ...mapActions([
      'setMessageDeliveries',
      'deleteMessageDelivery',
      'setUsers',
      'setGroups',
      'setDevices',
      'setFullscreenLoading'
    ]),
    async doLoad(storeCheck) {
      if (this.loading) {
        return
      }
      this.loading = true
      Promise.all([
        this.initMessageDeliveries(storeCheck),
        this.initUsers(storeCheck),
        this.initGroups(storeCheck),
        this.initDevices(storeCheck)
      ]).then(() => {
        this.loading = false
      })
    },
    async initMessageDeliveries(storeCheck) {
      if (storeCheck && this.$store.getters.messageDeliveries.length > 0) {
        return
      }
      let res
      try {
        res = await getMessageDeliveries()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setMessageDeliveries(res.message_deliveries)
    },
    async initUsers(storeCheck) {
      if (storeCheck && this.$store.getters.users.length > 0) {
        return
      }
      let res
      try {
        res = await getUsers()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setUsers(res.users)
    },
    async initGroups(storeCheck) {
      if (storeCheck && this.$store.getters.groups.length > 0) {
        return
      }
      let res
      try {
        res = await getGroups()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setGroups(res.groups)
    },
    async initDevices(storeCheck) {
      if (storeCheck && this.$store.getters.devices.length > 0) {
        return
      }
      let res
      try {
        res = await getDevices()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setDevices(res.devices)
    },
    doCreate() {
      this.editGroupId = ''
      this.editMessageId = ''
      this.dialog = true
    },
    doEdit(groupId, messageId) {
      this.editGroupId = groupId
      this.editMessageId = messageId
      this.dialog = true
    },
    async doDelete(message) {
      this.setFullscreenLoading(true)
      try {
        await deleteMessageDelivery(message)
        this.deleteMessageDelivery(message)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.setFullscreenLoading(false)
    },
    close() {
      this.editGroupId = ''
      this.editMessageId = ''
      this.dialog = false
    },
    displayGroupName(id) {
      const group = this.groupNames.find(g => g.id == id)
      if (!group) {
        return ''
      }
      return group.name
    },
    displayStatusName(status) {
      const name = this.messageDeliveryStatusList.find(s => s.value === status)
      if (name === undefined) {
        return ''
      }
      return name.label
    },
    displayTargetTypeName(targetType) {
      const name = this.messageDeliveryTargetTypeList.find(
        s => s.value === targetType
      )
      if (name === undefined) {
        return ''
      }
      return name.label
    }
  },
  filters: {
    moment(timestamp) {
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm:ss')
    }
  }
}
</script>
